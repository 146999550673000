@mixin absolute-position($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  @if $top {
    top: $top;
  }

  @if $right {
    right: $right;
  }

  @if $bottom {
    bottom: $bottom;
  }

  @if $left {
    left: $left;
  }
}

@mixin fixed-position($top: null, $right: null, $bottom: null, $left: null) {
  position: fixed;

  @if $top {
    top: $top;
  }

  @if $right {
    right: $right;
  }

  @if $bottom {
    bottom: $bottom;
  }

  @if $left {
    left: $left;
  }
}

@mixin absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-stretch {
  @include absolute-position(0, 0, 0, 0);
}

.fixed-stretch {
  @include fixed-position(0, 0, 0, 0);
}
