@use "variables" as *;
@use "utils/utils" as *;
@use "base/base" as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

.material-symbols-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 24;

  &.fill {
    font-variation-settings:
      'FILL' 1,
      'wght' 500,
      'GRAD' 0,
      'opsz' 24;
  }
}

html {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  color: var(--fg-color);
  background: var(--main-bg-color);
  background: var(--bg-elevated-color);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}