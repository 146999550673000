@use "src/styles/utils/flex.scss" as *;

.Day {
  @include flexbox($direction: column, $align: center, $gap: 0.125rem);

  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border-width: 1.75px;
  border-style: solid;
  width: 4.875rem;
  padding: 0.5rem 3rem;

  &Tasks {
    @include flexbox($justify: center, $align: center, $gap: 0.125rem);
  }

  &Task {
    width: 0.75rem;
    min-width: 0.75rem;
    height: 0.75rem;
    line-height: 0.75rem;
    border-radius: 50%;
  }
}