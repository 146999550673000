:root {
  --fg-color: rgb(20, 20, 20);
  --fg-muted-color: rgba(20, 20, 20, 0.5);
  --fg-very-muted-color: rgba(20, 20, 20, 0.25);
  --primary-color: rgb(230, 161, 154);
  --main-bg-color: rgb(255, 255, 255);
  --divider-color: rgb(180, 180, 180);
  --contrast-fg-color: rgb(255, 255, 255);
  --small-btn-border-radius: 0.25rem;
  --btn-border-radius: 0.5rem;
  --bg-modal-color: rgb(255, 255, 255);
  --bg-overlay-color: rgba(255, 255, 255, 0.75);
  --bg-elevated-color: rgb(240, 240, 240);
  --neutral-btn-color: rgb(200, 200, 200);
  --color-classic: #000;
  --color-day: #f78d86;
  --color-night: #5e95ca;
  --color-nature: #69a759;
  --color-white: #fff;
  --color-black: #000;
  --color-dawn: #f27c49;
  --color-sunshine: #ffc630;
  --color-forest: #07895d;
  --color-twilight: #d54449;
  --color-midnight: #0268cb;
  --color-classic: #000;
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: rgb(40, 40, 40);
    --fg-color: rgb(230, 230, 230);
    --fg-muted-color: rgba(230, 230, 230, 0.5);
    --fg-very-muted-color: rgba(230, 230, 230, 0.25);
    --divider-color: rgb(100, 100, 100);
    --bg-modal-color: rgb(30, 30, 30);
    --bg-overlay-color: rgba(0, 0, 0, 0.75);
    --bg-elevated-color: rgb(60, 60, 60);
    --neutral-btn-color: rgb(120, 120, 120);
    --color-classic: #ccc;
    --color-day: #f88e87;
    --color-night: #5e95ca;
    --color-nature: #77b247;
    --color-white: #fff;
    --color-black: #000;
    --color-dawn: #f27c49;
    --color-sunshine: #ffc630;
    --color-forest: #07895d;
    --color-twilight: #d54449;
    --color-midnight: #0268cb;
    --color-classic: #000;
  }
}

// SPACES
:root {
  --ss: 0.25rem;
  --s: 0.5rem;
  --s2: 1rem;
  --s3: 1.5rem;
  --s4: 2rem;
}

// FONT_SIZES & LINE HEIGHTS
:root {
  --fs-xs: 0.75rem;
  --fs-s: 0.875rem;
  --fs-m: 1rem;
  --fs-l: 1.25rem;
  --fs-xl: 1.5rem;
  --fs-h2: 2rem;
  --fs-h1: 2.625rem;

  --lh-xs: 1.25rem;
  --lh-s: 1.375rem;
  --lh-m: 1.5rem;
  --lh-l: 1.5rem;
  --lh-xl: 2.25rem;
  --lh-h2: 2.5rem;
  --lh-h1: 3.625rem;
}

// ICON SIZES
:root {
  --is-xs: 0.75rem;
  --is-s: 1.25rem;
  --is-m: 1.5rem;
}

// Z_INDEX
:root {
  --z-add-task-modal: 22;
  --z-nav: 4;
}

// TRANSITIONS
:root {
  --standard-transition-duration: 150ms;
}

// OTHER
:root {
  --timeline-icon-size: 4rem;
  --timeline-right-margin: 1rem;
  // faster than using calc....
  --timeline-total-size: 4rem;
  --add-task-modal-width: 27.5rem;
}
