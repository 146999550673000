.welcome-to-structured-text-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.25rem;

  .logo-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .logo {
    width: 3.75rem;
    height: 3.75rem;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.125rem;
  }

  .welcome-text {
    text-align: center;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .structured-text {
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}