@use "src/styles/utils/flex.scss" as *;

.TimelineAddTask {
  @include inline-flexbox($direction: column, $justify: center, $gap: 1rem);

  flex: 1;

  padding: 0.5rem 0;

  &__Content {
    @include flexbox($align: center);

    height: 100%;
  }

  &__Message {
    @include flexbox();
  }
}
