@use "src/styles/utils/flex.scss" as *;

.TimePicker {
  @include flexbox($gap: 1rem, $justify: center);

  &__Input {
    height: 5rem;
    width: 6.25rem;
  }
}
