@use "src/styles/utils/flex.scss" as *;

.App {
  @include flexbox($direction: column);

  overflow: hidden;
}

.btn.btn-float {
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
}