@mixin flexbox($direction: row, $justify: flex-start, $align: stretch, $gap: null) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;

  @if $gap {
    gap: $gap;
  }
}

@mixin inline-flexbox($direction: row, $justify: flex-start, $align: stretch, $gap: null) {
  display: inline-flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;

  @if $gap {
    gap: $gap;
  }
}

.vertical-align-center {
  @include flexbox($direction: column, $justify: stretch, $align: center);
}

.flex {
  display: flex;
}
