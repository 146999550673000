@use "src/styles/utils/flex.scss" as *;

.Settings {
  &__TabContent {
    width: 40rem;
  }

  &__TabForm {
    min-width: 20rem;
  }

  &__Section {
    @include flexbox($direction: column, $gap: 0.5rem);

    padding-bottom: 2rem;
  }

  &__Footer {
    @include flexbox($direction: column, $align: flex-start, $gap: 0.5rem);

    hr {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  &__Label {
    @include flexbox($justify: space-between, $align: center, $gap: 0.5rem);

    width: 100%;
    padding-bottom: 0.2rem;
  }

  &__SyncStatus {
    @include inline-flexbox($align: center, $gap: 0.3rem);

    width: 100%;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;
    padding: 0.55rem 0.875rem;
  }

  &__ThemeContainer {
    @include flexbox($align: center, $gap: 0.5rem);
  }

  &__Theme {
    flex: 1;
    cursor: pointer;
    border-radius: 0.5rem;
    border-width: 2px;
    border-style: solid;
    overflow: hidden;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
