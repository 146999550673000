@use "src/styles/utils/flex.scss" as *;

.ColorSelector {
  @include flexbox($direction: column, $gap: 1rem);

  &__Palettes {
    @include flexbox($gap: 0.5rem);
  }

  &__Label {
    width: 2rem;
    height: 2rem;
  }
}
