@use "src/styles/variables" as *;
@use "src/styles/utils/flex.scss" as *;

.TimelineTimes {

  height: 100%;

  --progress: 50%;

  overflow: visible;
  position: relative;
  text-align: right;
}
