@use "src/styles/utils/flex.scss" as *;

.SelectDayBar {
  padding: 0 1rem;

  &__Days {
    @include flexbox($gap: 2rem);

    width: 100%;

    padding: 1rem 0rem;
  }

  &__Week {
    @include flexbox($align: center, $gap: 0.25rem, $justify: space-between);

    padding: 1.25rem 1rem;
  }

  &__Actions {
    @include flexbox($align: center, $gap: 0.25rem);
  }
}