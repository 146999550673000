@use "src/styles/variables" as *;
@use "src/styles/utils/position.scss" as *;
@use "src/styles/utils/flex.scss" as *;

.TimelineTask {
  @include flexbox($justify: space-between, $align: center);
  position: relative;

  &__Time {
    @include inline-flexbox($align: baseline);
  }

  &__ContentWrapper {
    @include inline-flexbox($direction: column, $justify: center);
  }

  &__Content {
    @include inline-flexbox($direction: column, $justify: center, $gap: 0.5rem);

    flex: 1;
    cursor: pointer;
  }

  &__Subtask {
    @include inline-flexbox($align: center, $gap: 0.5rem);
  }
}

.overlappingMsg {
  color: var(--fg-muted-color);
  white-space: nowrap;
  position: absolute;
  // NOTE magic number to center msg in-between
  top: -5px;
  left: 0;
  font-size: var(--fs-xs);
}

.taskSubtask {
  color: var(--fg-muted-color);
}
