@use "src/styles/utils/flex.scss" as *;

.IconPicker {
  height: 25rem;
  width: 50vw;
  max-width: 30rem;
  min-width: 25rem;
  overflow-y: auto;

  &__Header {
    width: 50vw;
    max-width: 30rem;
    min-width: 25rem;
  }

  &__Section {
    padding-bottom: 1rem;
  }

  &__Entry {
    @include flexbox($justify: center, $align: center);

    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    cursor: pointer;
    user-select: none;
  }

  &__List {
    @include flexbox($gap: 0.5rem);

    flex-wrap: wrap;
    padding: 0.5rem 0;
    padding-inline-end: 0.5rem;
  }
}
