@use "src/styles/utils/flex.scss" as *;
@use "src/styles/utils/grid.scss" as *;

.TermsOfService {
  &__Container {
    width: 25.625rem;
    padding: 1.875rem 0 0 0;
    border-radius: 1rem 0 0 0;
  }

  &__Body {
    @include flexbox($direction: column,
      $justify: stretch,
      $align: center,
      $gap: 1rem);
  }
}