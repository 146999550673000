@use "src/styles/utils/flex.scss" as *;

.AllDayTask {
  @include flexbox($direction: column, $align: center);

  width: 6.25rem;

  &__Icon {
    @include flexbox($justify: center, $align: center);

    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    cursor: pointer;

    &:hover {
      filter: brightness(0.95);
      
      &[data-completed="true"] {
        filter: brightness(0.9) saturate(1.1);
      }
    }
  }
}
